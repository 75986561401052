import './scss/main.scss';

import LazyLoad from "vanilla-lazyload";
import { gsap, Power2 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Swiper, { Navigation, Pagination } from "swiper";
import { Fancybox } from "@fancyapps/ui";

// Initiate ScrollTrigger
gsap.registerPlugin(ScrollTrigger);



/*  Sticky Header
\*----------------------------------------------------------------------------*/
const body = document.body;
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;

  if (currentScroll <= 50) {
    body.classList.remove(scrollUp);
    return;
  }

  if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
    // Down
    body.classList.remove(scrollUp);
    body.classList.add(scrollDown);
  } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
    // Up
    body.classList.remove(scrollDown);
    body.classList.add(scrollUp);
  }
  lastScroll = currentScroll;
})


/*  Lazy load images
\*----------------------------------------------------------------------------*/
const myLazyLoad = new LazyLoad({
  class_loading: "loading",
  class_loaded: "loaded",
  elements_selector: ".lazy",
});

myLazyLoad.update();


/*  Animation sections
\*----------------------------------------------------------------------------*/
const sections = gsap.utils.toArray('.o-component .reveal');

// Set things up
gsap.set(sections, {
  autoAlpha: 0,
  y: 100
});

sections.forEach((section, i) => {
  // Set up your animation
  let setDelay = section.dataset?.delay;
  const anim = gsap.to(section, {
    duration: 1,
    autoAlpha: 1,
    y: 0,
    paused: true,
    delay: setDelay ? setDelay : 0,
    ease: 'Power2.out'
  });

  // Use callbacks to control the state of the animation
  ScrollTrigger.create({
    trigger: section,
    end: "bottom bottom",
    once: true,
    onEnter: self => {
      // If it's scrolled past, set the state
      // If it's scrolled to, play it
      self.progress === 1 ? anim.progress(1) : anim.play()
    }
  });
});


/*  Animation images
\*----------------------------------------------------------------------------*/
let revealContainers = document.querySelectorAll(".a-image__reveal");

revealContainers.forEach((container) => {
  let image = container.querySelector("img");
  let setDelay = image.dataset?.delay;
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      toggleActions: "restart none none reset",
    },
  });

  tl.set(container, {
    autoAlpha: 1,
    delay: setDelay ? `-${setDelay / 10}` : 0,
  });
  tl.from(container, 1.5, {
    xPercent: -100,
    ease: Power2.out,
  });
  tl.from(image, 1.5, {
    xPercent: 100,
    scale: 1.8,
    delay: -1.5,
    ease: Power2.out,
  });
});

/*  Swiper
\*----------------------------------------------------------------------------*/
const swiper = new Swiper(".swiper", {
  modules: [Navigation, Pagination],
  autoHeight: false,
  speed: 800,
  centeredSlides: true,
  slidesPerView: "auto",
  spaceBetween: 24,
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  pagination: {
    el: ".m-slider__dots",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  grabCursor: true,
  breakpoints: {
    640: {
      slidesPerView: 1.3,
      spaceBetween: 72,
    },
    1400: {
      slidesPerView: 1.4,
      spaceBetween: 96,
    },
    1800: {
      slidesPerView: 1.8,
      spaceBetween: 120,
    }
  },
});

/*  Fancybox
\*----------------------------------------------------------------------------*/
Fancybox.bind("[data-fancybox]", {
  Toolbar: {
    display: [
      "close"
    ],
  },
  Thumbs: {
    autoStart: false,
  },
});
